// core-js comes with Next.js. So, you can import it like below
import Map from 'core-js/library/es6/map'
import Set from 'core-js/library/es6/set'
import WeakMap from 'core-js/library/es6/weak-map'
import assign from 'core-js/library/modules/_object-assign'
import 'core-js/fn/object/assign'
import 'core-js/fn/string/ends-with'// unsure if needed
import 'core-js/fn/string/starts-with'
import 'core-js/fn/string/includes'
import 'core-js/fn/array/includes'
import 'core-js/fn/array/find'
import 'core-js/fn/array/find-index'
import 'core-js/fn/array/values'

window.Map = window.Map || Map
window.Set = window.Set || Set
window.WeakMap = window.WeakMap || WeakMap
